import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/index'
import router from './router'
import moment from 'moment'

// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

Vue.config.productionTip = false

import vMultiselectListbox from 'vue-multiselect-listbox'
Vue.component('v-multiselect-listbox', vMultiselectListbox)



new Vue({
  vuetify,
  render: h => h(App),
  router,
  store: store
}).$mount('#app')


Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY hh:mm')
  }
});
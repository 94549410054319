import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/create',
        name: 'Новый проект',
        component: () => import('../views/CreateProject.vue')
    },
    {
        path: '/project/:id/edit',
        name: 'Редактирование проекта',
        component: () => import('../views/CreateProject.vue')
    },
    {
        path: '/project/:id',
        name: 'Просмотр проекта',
        component: () => import('../views/DetailProject.vue')
    },
    {
        path: '/tender/:slug',
        name: 'Ответить на проект',
        component: () => import('../views/AnswerProject.vue')
    },
    {
        path: '/login',
        name: 'Авторизация',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/iul-generation',
        name: 'ИУЛы',
        component: () => import('../views/iuls/IulProjects')
    },
    {
        path: '/edit_iul_project/:id',
        name: 'Редактирование объекта',
        component: () => import('../views/iuls/CreateIulProject.vue')
    },
    {
        path: '/create_iul_project',
        name: 'Создание объекта',
        component: () => import('../views/iuls/CreateIulProject.vue')
    },
    {
        path: '/iul_project/:id',
        name: 'Работа с объектом',
        component: () => import('../views/iuls/DetailIulProject.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

const pathStartsWith = function(arr, val) {
  return arr.some(function(arrVal) {
    return val.startsWith(arrVal);
  });
}

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/tender'];
    const authRequired = !pathStartsWith(publicPages, to.path)

    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    }

    next();
})

export default router;

<template>
  <v-app id="inspire">
    <overall-header/>
    <router-view/>
  </v-app>
</template>

<script>

import OverallHeader from "@/components/OverallHeader";

export default {
  components: {OverallHeader},

}
</script>
import createCrudModule from 'vuex-crud';

export default createCrudModule({
    resource: 'iul_projects',
    customUrlFn(id) {
        // id will only be available when doing request to single resource, otherwise null
        // type is the actions you are dispatching: FETCH_LIST, FETCH_SINGLE, CREATE, UPDATE, REPLACE, DESTROY
        const rootUrl = `/api_v3/iul_project/`;
        return id ? `${rootUrl}${id}/` : rootUrl;
    },
});
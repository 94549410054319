var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"lighten-3"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"padding":"5vh"},attrs:{"align":"start","justify":"space-around"}},[_c('h3',[_vm._v("Все проекты")]),_c('v-btn',{attrs:{"tile":"","color":"success"},on:{"click":_vm.createProjectLink}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Создать проект ")],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.projectsList,"items-per-page":25,"dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v(_vm._s(item.id))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.buildTitle(item.status))+" ")]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.deadline))+" ")]}},{key:"item.answers_deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.answers_deadline))+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v(_vm._s(item.title))])]}},{key:"item.archived",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.archived),callback:function ($$v) {_vm.$set(item, "archived", $$v)},expression:"item.archived"}})]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.getLink(item)),expression:"getLink(item)"}],attrs:{"small":""}},[_vm._v("mdi-content-copy")]),_c('a',{attrs:{"href":item.link}},[_vm._v(_vm._s(item.link))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.archiveItem(item)}}},[_vm._v(" mdi-archive ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
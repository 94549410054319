<template>
  <v-app-bar
      app
      color="white"
      flat
      dense
  >


    <v-container class="py-0 fill-height" v-if="user.role == 'guest'">
      <v-avatar
          color="grey darken-1 shrink"
          size="32"
      ></v-avatar>
      <strong v-html="user.full_name" class="pa-2"></strong>
    </v-container>

    <v-container class="py-0 fill-height" v-else>
      <v-avatar
          right
          color="grey darken-1 shrink"
          size="32"
      ></v-avatar>
      <strong v-html="user.full_name" class="pa-2"></strong>
      <v-btn class="ml-10" @click="$router.push('/')">
        Все проекты
      </v-btn>
      <v-btn class="ml-10" @click="$router.push('/iul-generation')">
        ИУЛы
      </v-btn>
      <router-link to="/login" class="pa-5">Logout</router-link>
    </v-container>

  </v-app-bar>
</template>

<script>
import Axios from '@/helpers/axiosConfig';
import csrftoken from '@/helpers/csrftoken';

export default {
  name: 'overall-header',

  data: () => ({
    links: [
      {
        title: 'Главная',
        href: '/'
      }
    ],
    user: {},
    dialog: false,
    csrftoken: csrftoken,
  }),

  created() {
    let that = this;
    Axios.get('/api_v3/current-user/').then(function(response) {
      that.user = response.data;
    });
  }
}
</script>

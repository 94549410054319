import Vue from 'vue';
import Vuex from 'vuex';

import projects from '@/store/projects';
import iul_projects from '@/store/iul_projects';
import iul_workers from '@/store/iul_workers';
import statuses from '@/store/statuses';
import sectiontypes from '@/store/sectiontypes';
import iul_sectiontypes from '@/store/iul_sectiontypes';
import tenders from '@/store/tenders';
import users from '@/store/users';
import { authentication } from './authentication.module';
import { alert } from './alert.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  modules: {
    projects,
    iul_projects,
    iul_workers,
    iul_sectiontypes,
    statuses,
    sectiontypes,
    tenders,
    users,
    authentication,
    alert,
  }
});

import createCrudModule from 'vuex-crud';

export default createCrudModule({
    resource: 'tenders',
    customUrlFn(id, type, slug) {
        console.log('tenders customUrlFn', id, type, slug)
        // id will only be available when doing request to single resource, otherwise null
        // type is the actions you are dispatching: FETCH_LIST, FETCH_SINGLE, CREATE, UPDATE, REPLACE, DESTROY
        const rootUrl = `/api_v3/tender/`;
        return slug ? `${rootUrl}${slug}/` : rootUrl;

    }
});
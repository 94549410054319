import axios from 'axios'
import {API_URL} from "@/helpers/const";
axios.defaults.baseURL = API_URL;

const debugAxiosConfig = true;

axios.interceptors.request.use(
    async config => {
        let user = JSON.parse(localStorage.getItem('user'));
        if (debugAxiosConfig) {
            console.log("axiosConfig > user", user);
            console.log("axiosConfig > config", config);
        }
        if (user !== null && user.token !== null) {
            config.headers.Authorization = "JWT " + user.token
        } else {
            config.headers.Authorization = null;
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
);

export default axios
import createCrudModule from 'vuex-crud';

export default createCrudModule({
    resource: 'iul_worker',
    customUrlFn(id) {
        // id will only be available when doing request to single resource, otherwise null
        // type is the actions you are dispatching: FETCH_LIST, FETCH_SINGLE, CREATE, UPDATE, REPLACE, DESTROY
        const rootUrl = `/api_v3/iul_worker/`;
        return id ? `${rootUrl}${id}/` : rootUrl;
    },
    // onCreateSuccess: (a,b,c,d,e) => {
    //     console.log(a,b,c,d,e)
    // },
});